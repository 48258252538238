import React from "react"
import {graphql} from "gatsby"
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import images from "../components/images";
import MixPostGrid from "../components/mix-post-grid";
import SharePopupModel from "../components/share-popup-model";
import {Helmet} from "react-helmet";
export default ({data}) => {

    let color = [
        {backgroundColor:'#ea4b4b'},
        {backgroundColor:'#5d5d5d'},
        {backgroundColor:'#034389'},
        {backgroundColor:'#0091b4'}];

    let img = [images.redDottedHalfCircle, images.triangle];

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>Blogs list | Metrify India</title>
                <meta name="description" content="All blogs of Legal Metrology"/>

                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="Blogs list | Metrify India"/>
                <meta property="og:description" content="All blogs of Legal Metrology"/>
                <meta property="og:url" content="https://www.metrifyindia.com/blogs/"/>
                <meta property="og:site_name" content="Metrify India"/>
                <meta property="og:image" content={data.site.siteMetadata.siteUrl + images.ogLogo} />
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:label1" content="Written by"/>
                <meta name="twitter:data1" content="admin"/>
                <meta name="twitter:label2" content="Est. reading time"/>
                <meta name="twitter:data2" content="14 minutes"/>
                <script type="application/ld+json"
                        className="yoast-schema-graph">{`{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://www.metrifyindia.com/#website","url":"https://www.metrifyindia.com/","name":"Metrify India","description":"All blogs of Legal Metrology","potentialAction":[{"@type":"SearchAction","target":"https://www.metrifyindia.com/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://www.metrifyindia.com/blogs/","url":"https://www.metrifyindia.com/blogs/","name":"Blogs list | Metrify India","isPartOf":{"@id":"https://www.metrifyindia.com/#website"},"datePublished":"2021-04-10T17:52:00+00:00","dateModified":"2021-04-15T07:33:19+00:00","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.metrifyindia.com/blogs/"]}]}]}`}</script>
                <link rel="alternate" type="application/rss+xml" title="Metrify India &raquo; Feed"
                      href="https://www.metrifyindia.com/feed/"/>
                <link rel="alternate" type="application/rss+xml" title="Metrify India  &raquo; Comments Feed"
                      href="https://www.metrifyindia.com/comments/feed/"/>
            </Helmet>

            <Header />
            <Menu/>

            <div className="section-title pt-5">
                <h1 className="section-title-heading fsm-theme-heading">All Posts</h1>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div key={0}>
                                <MixPostGrid data={{newsArr:data.allWordpressPost.edges,regulatoryArr:data.allWordpressWpRegulatoryUpdates.edges,insightArr:data.allWordpressWpRegulatoryInsight.edges}} key={1} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            <SharePopupModel name={"shareModal"} />

        </>
    )
}

export const pageQuery = graphql`
  query {
  site {
      siteMetadata {
        title
        siteUrl
      }
    }
   allWordpressPost(sort: {fields: [date]}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            author {
              name
            }
            compliance_services
          }
        }
      }
        allWordpressWpRegulatoryInsight(sort: {fields: [date]}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            compliance_services
            content
          }
        }
      }
      allWordpressWpRegulatoryUpdates(sort: {fields: [date]}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            compliance_services
            
            acf {
              date_of_implementation
              draft_or_gazette
              impacted_products
              reference
              reference_regulation
              summary
            }
            author {
              name
            }
          }
        }
      }
  }`
